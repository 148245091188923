import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageHeader } from '../page-header/page-header';

import './page-payment-fail.css';

const PAYMENT_COOKIE_NAME = 'PITCHER_PAYMENT';
const match = document.cookie.match(new RegExp(`${PAYMENT_COOKIE_NAME}=fail-([0-9]+)-([^;]+)`, 'i'));

export const PagePaymentFail = () => {
    const orderID = match ? parseInt(match[1], 10) : null;
    const orderHash = match ? match[2] : null;
    const currentOrigin = window.location.origin;

    if (orderID && orderHash) {
        return (
            <div className="page-payment-fail">
                <PageHeader text="Ошибка" />
                <p>К сожалению, оплата заказа не была завершена.</p>
                <p>Если заказ все же актуален, вы можете оплатить его со страницы<br />
                    <Link to={`/order/${orderID}-${orderHash}`}>{currentOrigin}/order/{orderID}-{orderHash}</Link>.
                </p>
            </div>
        );
    }

    return <Redirect to="/" />;
};
