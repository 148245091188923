import React from 'react';
import { Map, Placemark, Polygon, YMaps, ZoomControl } from 'react-yandex-maps';
import { PageHeader } from '../page-header/page-header';

import './page-delivery.css';

function is_touch_device4() {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = query => window.matchMedia(query).matches;

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    return mq(['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(''));
}

export const PageDelivery = () => (
    <div className="page-delivery">
        <PageHeader text="Доставка и оплата" />
        <div className="page-delivery__body">
            <div className="wysiwyg-content">
                <p>Мы доставим кофе до вашей двери в день заказа, если вы находитесь в Санкт-Петербурге.
                    Оплата происходит на сайте банковской картой MasterCard, Visa, Maestro, Мир или с помощью Apple Pay.
                </p>
                <p>Обычно мы доставляем кофе в день заказа. При заказе после 18:00, на следующий день. <br/>
                    В предновогодний период, или перед другими праздниками, к сожалению, сроки доставки могут быть другими.</p>
                <h2>Стоимость доставки</h2>
                <p>Самовывоз в любой из наших кофеен бесплатно.</p>
                <p>Доставка по Санкт-Петербургу:</p>
                <ul>
                    <li>бесплатно при заказе на сумму от 1900р.</li>
                    <li>400р при заказе менее 1900р</li>
                </ul>
                <p>Доставка в другие города РФ осуществляется компанией СДЭК. В этом случае доставка оплачивается
                    отдельно при получении. Мы отвозим на склад СДЭК в Санкт-Петербурге бесплатно, а вы оплачиваете
                    услуги транспортной компании при получении кофе.
                </p>
                <p>
                    Обычно стоимость доставки до пункта выдачи СДЭК стоит 200-300 рублей, а до квартиры 400-500 рублей.
                    Мы свяжемся с вами, скажем точную стоимость и вы выберете вариант доставки.
                </p>
                <h2>Самовывоз</h2>
                <p>Вы можете забрать заказ из наших кофеен:</p>
                <ul>
                    <li>PITCHER на Марата 2 работает по графику понедельник-пятница с 9 до 22, суббота-воскресенье
                        с 10 до 22.
                    </li>
                    <li>PITCHER на Бассейной 12 работает по графику понедельник-пятница с 8 до 22, суббота с 9 до 22,
                        воскресенье с 10 до 22
                    </li>
                </ul>
                <p>Когда вы покупаете кофе в кофейне, мы всегда угощаем напитком бесплатно.</p>
            </div>
            <YMaps>
                <div className="page-delivery__map">
                    <Map
                        className="page-delivery__map-node"
                        height={450}
                        state={{
                            center: [59.949, 30.335],
                            zoom: 10,
                            behaviors: is_touch_device4() ? ['multiTouch'] : ['drag'],
                        }}
                    >
                        <ZoomControl defaultOptions={{ size: 'small' }} />

                        <Polygon
                            geometry={[
                                [
                                    [59.82034696272947, 30.376059804686754],
                                    [59.83377829380344, 30.44470373145184],
                                    [59.8499327350813, 30.46521726599796],
                                    [59.85403488332684, 30.494142208014207],
                                    [59.854434275904254, 30.50283256522252],
                                    [59.85664703959532, 30.511522922430828],
                                    [59.86564768773096, 30.528560314093607],
                                    [59.91455808134095, 30.496030483161306],
                                    [59.95042790905175, 30.500710745355555],
                                    [59.9713937545718, 30.47812137888812],
                                    [60.0461723029095, 30.42524967478593],
                                    [60.05973457649043, 30.35830173777492],
                                    [60.0618404968236, 30.335577179765245],
                                    [60.065619505360544, 30.31731581755633],
                                    [60.06742370605423, 30.306317601443364],
                                    [60.06588164378219, 30.29875261286943],
                                    [60.04884051934509, 30.22942519255627],
                                    [59.99434655470879, 30.184177001465734],
                                    [59.93713098270051, 30.185303582071793],
                                    [59.89187695527546, 30.200066460489825],
                                    [59.86301137741714, 30.201268090128554],
                                    [59.845178963644095, 30.211396111368856],
                                    [59.81320343294045, 30.325035942911754],
                                    [59.82034696272947, 30.376059804686754],
                                ]
                            ]}
                            options={{
                                fillColor: '#56db40',
                                fillOpacity: 0.3,
                                strokeColor: '#1bad03',
                                strokeOpacity: 0.9,
                                strokeWidth: 3,
                            }}
                        />

                        <Placemark
                            geometry={[59.93076594426666, 30.355756926023567]}
                            properties={{
                                iconCaption: 'PITCHER на Марата 2',
                                balloonContent: 'Кофейня;<br>ул. Марата, дом 2<br>пн-пт с 9 до 22, сб-вс с 10 до 22',
                            }}
                            options={{
                                preset: 'islands#circleIcon',
                                iconColor: '#1e98ff',
                                hideIconOnBalloonOpen: false,
                            }}
                            modules={['geoObject.addon.balloon']}
                        />

                        <Placemark
                            geometry={[59.866243, 30.314913]}
                            properties={{
                                iconCaption: 'PITCHER на Бассейной 12',
                                balloonContent: 'Кофейня;<br>ул. Бассейная, дом 12'
                                    + '<br>пн-пт с 8 до 22, сб с 9 до 22, вс с 10 до 22',
                            }}
                            options={{
                                preset: 'islands#circleIcon',
                                iconColor: '#1e98ff',
                                hideIconOnBalloonOpen: false,
                            }}
                            modules={['geoObject.addon.balloon']}
                        />
                    </Map>
                </div>
            </YMaps>
            <div className="wysiwyg-content">
                <h2>Возврат</h2>
                <p>Мы уверены в высоком качестве нашего кофе, но по закону обязаны рассказать,
                    как вы можете его вернуть.
                </p>
                <p>Согласно Закону РФ «О защите прав потребителей» вернуть продукты питания, в том числе кофе,
                    можно в случае если продукция не соответствует стандартам или санитарным нормам: истек срок
                    годности; продукт испортился и стал непригодным для употребления в пищу; в упаковке находится
                    содержимое, не соответствующее описанию; обнаружено наличие посторонних предметов.
                </p>
                <p>Если вы обнаружили один из этих признаков в кофе, свяжитесь
                    с нами <a href="mailto:pitcherbar@gmail.com">pitcherbar@gmail.com</a> или по
                    телефону <a href="tel:+79810397912">+79810397912</a>. Мы вернем Вам деньги.
                </p>
            </div>
        </div>
    </div>
);
