import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { PageHeader } from '../page-header/page-header';

import './page-order-item.css';
import { sendForm } from '../../helpers/submit-form';

export const PageOrderItem = () => {
    const { id, hash } = useParams();
    const [loading, setLoading] = useState(true);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [positions, setPositions] = useState([]);
    const [total, setTotal] = useState(null);
    const onPayClick = () => {
        setFormSubmitted(true);

        fetch('/api/order/pay', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: [
                `${encodeURIComponent('order-id')}=${encodeURIComponent(id)}`,
                `${encodeURIComponent('order-hash')}=${encodeURIComponent(hash)}`,
            ].join('&'),
        })
            .then(response => response.json())
            .then((response) => {
                if (response.status === true) {
                    sendForm(response.formData);
                } else if (response.errorMsg) {
                    setErrorMsg(response.errorMsg);
                }
            })
            .catch(() => {
                setErrorMsg('PAYMENT_REQUEST_FAIL');
                setFormSubmitted(false);
            });
    };

    if (!id || !hash) {
        return <Redirect to="/404" />;
    }

    useEffect(() => {
        fetch(`/api/order/${id}?hash=${hash}`)
            .then(response => response.json())
            .then((responseJson) => {
                if (responseJson.status === true) {
                    if (responseJson.ready === true) {
                        setIsReady(true);
                    } else {
                        setPositions(responseJson.positions);
                        setTotal(responseJson.total);
                    }
                } else if (responseJson.error) {
                    setErrorMsg(responseJson.error);
                }

                setLoading(false);
            })
            .catch(() => setErrorMsg('ORDER_REQUEST_FAILURE'));
    }, []);

    if (errorMsg) {
        if (errorMsg === 404) {
            return <Redirect to="/404" />;
        }

        return (
            <div className="page-order-item">
                <PageHeader text="Ошибка" />
                <p>К сожалению, не удалось получить данные заказа.
                    Сообщите нам по телефону <a href="tel:+79810397912">+79810397912</a> или напишине
                    на почту <a href="mailto:pitcherbar@gmail.com">pitcherbar@gmail.com</a> об этом и мы разберемся
                    с этой досадной ситуацией.
                </p>
                <p>Ваш номер заказа: <strong>{id}</strong><br />
                    Ошибка: <strong>{errorMsg}</strong>
                </p>
                <p>
                    <Link to="/">Главная страница</Link>
                </p>
            </div>
        );
    }

    if (isReady) {
        return (
            <div className="page-order-item">
                <PageHeader text="Спасибо" />
                <p>Ваш заказ <strong>уже</strong> успешно оплачен. Его номер&nbsp;<strong>{id}</strong>.<br />
                    Мы свяжемся с Вами для уточнения деталей.
                </p>
                <p><Link to="/">Вернуться на главную</Link></p>
            </div>
        );
    }

    if (positions && positions.length) {
        return (
            <div className="page-order-item">
                <PageHeader text="Заказ" />
                <div className="page-order-item__list">
                    {positions.map(orderPosition => (
                        <li className="page-order-item__list-item" key={orderPosition.uid}>
                            {orderPosition.text}&nbsp;<span className="page-order-item__list-item-price">
                                {orderPosition.price}</span>
                        </li>
                    ))}
                </div>
                <div className="page-order-item__total">Итого: {total}р</div>
                <div className="page-order-item__submit">
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={formSubmitted}
                        onClick={onPayClick}
                    >Оплатить</Button>
                </div>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="page-order-item waiting">
                <p>Ожидаем данные заказа</p>
                <p className="page-order-item__waiting">
                    <svg
                        className="page-order-item__loader"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                    >
                        <path
                            d="M288 24.103v8.169a11.995 11.995 0 0 0 9.698 11.768C396.638 63.425 472 150.461 472 256c0 118.663-96.055 216-216 216-118.663 0-216-96.055-216-216 0-104.534 74.546-192.509 174.297-211.978A11.993 11.993 0 0 0 224 32.253v-8.147c0-7.523-6.845-13.193-14.237-11.798C94.472 34.048 7.364 135.575 8.004 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.789 504 256c0-121.187-86.924-222.067-201.824-243.704C294.807 10.908 288 16.604 288 24.103z"
                            fill="#cccccc"
                        />
                    </svg>
                </p>
            </div>
        );
    }

    return <Redirect to="/error500" />;
};
