import React from 'react';
import { Link } from 'react-router-dom';
import './page-error.css';

export const PageError = props => (
    <div className="page-error">
        <h2 className="page-error__title">Что-то пошло не так</h2>
        <p className="page-error__p">К сожалению, произошла ошибка
            {props.code > 499 ? ' сервера' : ' запроса'}. Мы уже знаем о ней и ищем пути решения.<br />
            Извините за неудобство.
        </p>
        <p className="page-error__p"><Link to="/">Главная страница</Link></p>
    </div>
);
