export const getProductCost = (instance, type) => {
    if (!instance || !instance.price) {
        return 0;
    }

    if (instance.discount > 0 && instance.discount <= 100) {
        return Math.round(parseFloat(instance.price) * (100 - instance.discount) / 100);
    }

    return parseFloat(instance.price);
};
