import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import SvgIcon from '@material-ui/core/SvgIcon';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

import './layout-header.css';
import logo from './logo.svg';

export const LayoutHeader = (props) => {
    const [scrollOnTop, setScrollOnTop] = useState(true);

    useEffect(() => {
        const scrollHandler = () => {
            setScrollOnTop(window.pageYOffset <= 0);
        };

        window.addEventListener('scroll', scrollHandler, false);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [scrollOnTop, setScrollOnTop]);

    return (
        <header className="layout-header">
            <div className={scrollOnTop ? 'layout-header__fixed' : 'layout-header__fixed with-shadow'}>
                <Container>
                    <div className="layout-header__inner">
                        {props.back
                            ? (
                                <Link
                                    to={props.back}
                                    className="layout-header__link layout-header__link-back"
                                    title="Назад"
                                >
                                    <SvgIcon aria-label="Back" className="layout-header__link-icon">
                                        <ArrowBackIcon />
                                    </SvgIcon>
                                </Link>
                            )
                            : ''}
                        {props.backFn
                            ? (
                                <span
                                    role="button"
                                    tabIndex={0}
                                    onClick={props.backFn}
                                    className="layout-header__link layout-header__link-back"
                                    title="Назад"
                                >
                                    <SvgIcon aria-label="Back" className="layout-header__link-icon">
                                        <ArrowBackIcon />
                                    </SvgIcon>
                                </span>
                            ) : ''
                        }
                        <Link to="/" className="layout-header__logo-link" title="Pitcher - espresso bar">
                            <img src={logo} className="layout-header__logo-img" alt="" />
                        </Link>
                        {props.basket && props.basket > 0
                            ? (
                                <Link
                                    to="/basket"
                                    className="layout-header__link layout-header__link-basket"
                                    title="Корзина"
                                >
                                    <SvgIcon aria-label="Basket" className="layout-header__link-icon">
                                        <ShoppingCartOutlinedIcon />
                                    </SvgIcon>
                                    {props.basket > 0
                                        ? <span className="layout-header__link-basket-num">{props.basket}</span>
                                        : null
                                    }
                                </Link>
                            )
                            : (
                                <span className="layout-header__link disabled layout-header__link-basket">
                                    <SvgIcon aria-label="Basket" className="layout-header__link-icon">
                                        <ShoppingCartOutlinedIcon />
                                    </SvgIcon>
                                </span>
                            )
                        }
                    </div>
                </Container>
            </div>
        </header>
    );
};
