import React from 'react';
import Container from '@material-ui/core/Container';
import './layout-footer.css';
import { Link } from 'react-router-dom';

export const LayoutFooter = () => (
    <footer className="layout-footer">
        <Container>
            <div className="layout-footer__inner">
                <div className="layout-footer__text">
                    Если у Вас возникли сложности с заказом, позвоните
                    по телефонам <a href="tel:+79810397912">+79810397912</a> или&nbsp;<a href="tel:+79992191006">+79992191006</a>.
                </div>
                <div className="layout-footer__links">
                    <Link to="/delivery" className="layout-footer__link">Доставка и оплата</Link>
                    <Link to="/terms" className="layout-footer__link">Оферта</Link>
                    <Link to="/privacy-policy" className="layout-footer__link">Политика конфиденциальности</Link>
                    <Link to="/requisites" className="layout-footer__link">Реквизиты компании</Link>
                </div>
            </div>
        </Container>
    </footer>
);
