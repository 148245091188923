import React from 'react';
import './splash.css';

export const Splash = (props) => {
    return (
        <div
            className={`splash ${props.class || ''}`}
            style={props.image ? { 'backgroundImage': `url("${props.image}")` } : null}
        >
            <div className="splash__content">
                {props.title
                    ? (
                        <div className="splash__content-inner">
                            <h3 className="splash__title">{props.title}</h3>
                            {props.description && (
                                <div className="splash__description">{props.description}</div>
                            ) }
                        </div>
                    )
                    : null }
            </div>
        </div>
    );
};
