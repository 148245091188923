import React from 'react';
import { PageHeader } from '../page-header/page-header';

import './page-requisites.css';

/* eslint-disable max-len */
export const PageRequisites = () => (
    <div className="page-requisites">
        <PageHeader text="РЕКВИЗИТЫ КОМПАНИИ" />
        <div className="page-requisites__body">
            <div className="wysiwyg-content">
                <table className="page-requisites__table">
                    <tr>
                        <td colSpan={2}>ИП Жуков Андрей Владимирович</td>
                    </tr>
                    <tr className="page-requisites__table-newline">
                        <td className="page-requisites__label">Юридичесий адрес:</td>
                        <td>617760, Пермская область, гор. Чайковский, ул. Мира 27-24</td>
                    </tr>
                    <tr>
                        <td className="page-requisites__label">Фактичекий адрес:</td>
                        <td>196070, г. Санкт-Петербург, ул. Бассейная 12</td>
                    </tr>
                    <tr>
                        <td className="page-requisites__label">ИНН:</td>
                        <td>592007476876</td>
                    </tr>
                    <tr>
                        <td className="page-requisites__label">ОГРНИП:</td>
                        <td>315595800068101</td>
                    </tr>
                    <tr>
                        <td className="page-requisites__label">Расчетный счет:</td>
                        <td>40802810132060000963</td>
                    </tr>
                    <tr>
                        <td className="page-requisites__label">Название Банка:</td>
                        <td>ФИЛИАЛ "САНКТ-ПЕТЕРБУРГСКИЙ" АО "АЛЬФА-БАНК"</td>
                    </tr>
                    <tr>
                        <td className="page-requisites__label">Кор.счет:</td>
                        <td>30101810600000000786</td>
                    </tr>
                    <tr>
                        <td className="page-requisites__label">БИК банка:</td>
                        <td>044030786</td>
                    </tr>
                    <tr className="page-requisites__table-newline">
                        <td className="page-requisites__label">ОКПО:</td>
                        <td>0201798557</td>
                    </tr>
                    <tr>
                    <td className="page-requisites__label">ОКАТО:</td>
                        <td>57401380000</td>
                    </tr>
                    <tr>
                        <td className="page-requisites__label">ОКТМО:</td>
                        <td>57701000001</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
);
