import React from 'react';
import { Splash } from '../splash/splash';
import { PageHeader } from '../page-header/page-header';
import { getHTML } from '../../data-provider/coffee-in-package-how-to';

export const PagePackagesHowToChoose = () => (
    <>
        <Splash image="/img/splash/how-to1.jpg" />
        <PageHeader text="Как выбрать кофе домой?" />
        <div className="wysiwyg-content" dangerouslySetInnerHTML={{ __html: getHTML() }} />
    </>
);
