import React, { useCallback, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageHeader } from '../page-header/page-header';

import './page-payment-success.css';

const PAYMENT_COOKIE_NAME = 'PITCHER_PAYMENT';
const match = document.cookie.match(new RegExp(`${PAYMENT_COOKIE_NAME}=success-([0-9]+)-([^;]+)`, 'i'));

export const PagePaymentSuccess = () => {
    const orderID = match ? parseInt(match[1], 10) : null;
    const [errorMsg, setErrorMsg] = useState(null);
    const [orderHash, setOrderHash] = useState(match ? match[2] : null);
    const fetchOrderStatus = useCallback(
        () => {
            fetch(`/api/payment/status?order-id=${orderID}&order-hash=${orderHash}`)
                .then(response => response.json())
                .then((responseJson) => {
                    if (responseJson.status === true) {
                        if (responseJson.ready === true) {
                            setOrderHash(null);
                        } else {
                            setTimeout(fetchOrderStatus, 2000);
                        }
                    } else if (responseJson.error) {
                        setErrorMsg(responseJson.error);
                    } else {
                        setOrderHash('RESPONSE_INVALID');
                    }
                })
                .catch(() => setOrderHash('STATUS_REQUEST_FAILURE'));
        },
        [setOrderHash],
    );

    useEffect(() => {
        if (orderHash && orderHash !== '0') {
            setTimeout(fetchOrderStatus, 2000);
        }
    }, [orderHash]);

    if (errorMsg) {
        return (
            <div className="page-payment-success">
                <PageHeader text="Ошибка" />
                <p>К сожалению, проверка статуса платежа завершена некорректно.
                    Сообщите нам по телефону <a href="tel:+79810397912">+79810397912</a> или напишите
                    на почту <a href="mailto:pitcherbar@gmail.com">pitcherbar@gmail.com</a> об этом и мы разберемся
                    с этой досадной ситуацией.
                </p>
                <p>Ваш номер запроса: <strong>{orderID}</strong><br />
                    Ошибка: <strong>{errorMsg}</strong>.
                </p>
            </div>
        );
    }

    if (orderHash && orderHash !== '0') {
        return (
            <div className="page-payment-success waiting">
                <p>Ожидаем подтверждения оплаты от платежной системы</p>
                <p className="page-payment-success__waiting">
                    <svg
                        className="page-payment-success__loader"
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                    >
                        <path
                            d="M288 24.103v8.169a11.995 11.995 0 0 0 9.698 11.768C396.638 63.425 472 150.461 472 256c0 118.663-96.055 216-216 216-118.663 0-216-96.055-216-216 0-104.534 74.546-192.509 174.297-211.978A11.993 11.993 0 0 0 224 32.253v-8.147c0-7.523-6.845-13.193-14.237-11.798C94.472 34.048 7.364 135.575 8.004 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.789 504 256c0-121.187-86.924-222.067-201.824-243.704C294.807 10.908 288 16.604 288 24.103z"
                            fill="#cccccc"
                        />
                    </svg>
                </p>
            </div>
        );
    }

    if (orderID && orderID > 0) {
        document.cookie = `${PAYMENT_COOKIE_NAME}=;path=/payment`;

        return (
            <div className="page-payment-success">
                <PageHeader text="Спасибо" />
                <p>Ваш заказ успешно оплачен. Его номер&nbsp;<strong>{orderID}</strong>.<br />
                    Мы свяжемся с Вами для уточнения деталей.
                </p>
                <p><Link to="/">Вернуться на главную</Link></p>
            </div>
        );
    }

    return <Redirect to="/" />;
};
