export const sendForm = (formData) => {
    const formEl = document.createElement('form');

    formEl.action = formData.action;
    formEl.method = 'POST';

    formData.params.forEach(([name, value]) => {
        const inputEl = document.createElement('input');

        inputEl.type = 'hidden';
        inputEl.name = name;
        inputEl.value = value;

        formEl.appendChild(inputEl);
    });

    document.body.appendChild(formEl);
    formEl.submit();
};
