import React from 'react';
import { Link } from 'react-router-dom';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { PageHeader } from '../page-header/page-header';
import { BasketList } from '../basket-list/basket-list';
import { getProduct } from "../../data-provider/product";
import { getProductCost } from "../../../utils/get-product-cost";
import { sendForm } from '../../helpers/submit-form';
import './page-basket.css';

const deliveryCost = 400;
const deliveryThreshold = 1900;

const getPayload = (order) => {
    const payload = [];

    order.forEach((orderPosition) => {
        for (let i = 0; i < orderPosition.quantity; i += 1) {
            payload.push({
                productType: orderPosition.type,
                productId: orderPosition.id,
            });
        }
    });

    return JSON.stringify(payload);
};

const getTotalCost = (basket) => {
    let totalPrice = 0;

    basket.forEach((orderPosition) => {
        const instance = getProduct(orderPosition.type, orderPosition.id);
        const price = getProductCost(instance, orderPosition.type);

        totalPrice += price * orderPosition.quantity;
    });

    return totalPrice;
};

const getFromStorage = (key) => {
    try {
        if (window.localStorage.hasOwnProperty(key)) {
            return window.localStorage.getItem(key);
        }
    } catch (e) {
        console.log(e);
    }

    return '';
};

const isValidFormState = (state) => {
    if (!['pickup', 'spb', 'other'].includes(state.delivery)) {
        return false;
    }

    if (state.name.length < 2) {
        return false;
    }

    if (state.phone.length < 6) {
        return false;
    }

    if (state.delivery !== 'pickup' && state.address.length < 2) {
        return false;
    }

    if (state.email < 5) {
        return false;
    }

    return true;
};

const mixValidationProp = state => ({
    ...state,
    isFormValid: isValidFormState(state),
});

export const PageBasket = (props) => {
    const { addItem, removeItem, basket } = props;
    const basketCost = getTotalCost(basket);
    const [formSubmitted, setFormSubmitted] = React.useState(false);
    const [formErrors, setFormErrors] = React.useState({});
    const [formState, setFormState] = React.useState(mixValidationProp({
        delivery: getFromStorage('userDelivery') || 'spb',
        name: getFromStorage('userName'),
        phone: getFromStorage('userPhone'),
        address: getFromStorage('userAddress'),
        email: getFromStorage('userEmail'),
        comments: '',
    }));

    const formFieldHandler = (e) => {
        const targetEl = e.target;
        const fieldName = targetEl.name;
        const key = `user${fieldName[0].toUpperCase()}${fieldName.slice(1)}`;

        setFormState(prevState => mixValidationProp({
            ...prevState,
            [fieldName]: targetEl.value,
        }));

        try {
            localStorage.setItem(key, targetEl.value);
        } catch (e) {
            console.log(e);
        }
    };

    const submit = (e) => {
        if (formState.isFormValid) {
            const formBody = [];
            const data = {
                delivery: formState.delivery,
                address: formState.delivery !== 'pickup' ? formState.address : null,
                name: formState.name,
                phone: formState.phone,
                email: formState.email,
                comment: formState.comment,
                payload: getPayload(basket),
            };

            setFormSubmitted(true);
            setFormErrors({});

            Object.keys(data).forEach((name) => {
                if (data[name]) {
                    formBody.push(`${encodeURIComponent(name)}=${encodeURIComponent(data[name])}`);
                }
            });

            fetch('/api/order', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formBody.join('&'),
            })
                .then(response => response.json())
                .then((response) => {
                    if (response.status === true) {
                        sendForm(response.formData);
                    } else {
                        if (response.errors) {
                            setFormErrors(response.errors);
                        } else if (response.error) {
                            setFormErrors({ other: JSON.stringify(response.error) });
                        }

                        setFormSubmitted(false);
                    }
                })
                .catch((error) => {
                    setFormErrors({ other: JSON.stringify(error) });
                    setFormSubmitted(false);
                });
        }

        e.preventDefault();
    };

    if (basket.length > 0) {
        return (
            <div className="page-basket">
                <PageHeader text="Заказ" />
                <div className="page-basket__list">
                    <BasketList basket={basket} addItem={addItem} removeItem={removeItem} />
                </div>
                <div className="page-basket__form">
                    <div className="order-form">
                        <h4 className="order-form__title">Получение</h4>
                        <div className="order-form__form">
                            <div className="order-form__delivery">
                                <RadioGroup
                                    className="order-form__delivery-group"
                                    name="delivery"
                                    defaultValue="spb"
                                    value={formState.delivery}
                                    onChange={formFieldHandler}
                                >
                                    <FormControlLabel
                                        value="pickup"
                                        control={<Radio color="primary" />}
                                        label={(
                                            <div className="order-form__delivery-variant">
                                                самовывоз из кофейни&nbsp;
                                                <span className="order-form__delivery-cost">(бесплатно)</span>
                                            </div>
                                        )}
                                    />
                                    <FormControlLabel
                                        value="spb"
                                        control={<Radio color="primary" />}
                                        label={(
                                            <div className="order-form__delivery-variant">
                                                доставка по Санкт-Петербургу&nbsp;
                                                <span className="order-form__delivery-cost">
                                                    {basketCost >= deliveryThreshold
                                                        ? <>(бесплатно)</>
                                                        : <>({deliveryCost}р, или <Link to={'/'}>добавить</Link> на&nbsp;{deliveryThreshold - basketCost}р для бесплатной доставки)</>
                                                    }
                                                </span>
                                            </div>
                                        )}
                                    />
                                    <FormControlLabel
                                        value="other"
                                        control={<Radio color="primary" />}
                                        label={(
                                            <div className="order-form__delivery-variant">
                                                доставка в другое место
                                            </div>
                                        )}
                                    />
                                </RadioGroup>
                                <div className="wysiwyg-content order-form__delivery-info">
                                    {formState.delivery === 'pickup' && (
                                        <ul>
                                            <li>
                                                PITCHER на Марата 2<br />
                                                понедельник-пятница с&nbsp;9 до&nbsp;22, суббота-воскресенье
                                                с&nbsp;10 до&nbsp;22.
                                            </li>
                                            <li>
                                                PITCHER на Бассейной 12<br />
                                                понедельник-пятница с&nbsp;8 до&nbsp;22, суббота с&nbsp;9
                                                до&nbsp;22, воскресенье с&nbsp;10 до&nbsp;22
                                            </li>
                                        </ul>
                                    )}
                                    {formState.delivery === 'spb' && (
                                        <p>
                                            Доставляем в день заказа, если вы сделали его до&nbsp;18:00.
                                            {basketCost >= deliveryThreshold || (
                                                ` Доставка бесплатная для заказов стоимостью от ${deliveryThreshold}р.`
                                            )}
                                        </p>
                                    )}
                                    {formState.delivery === 'other' && (
                                        <p>
                                            Доставка осуществляется компанией СДЭК. Оплачивается отдельно при получении.
                                        </p>
                                    )}
                                    <p>Подробнее о <Link to="/delivery" tabIndex={-1}>доставке и оплате</Link></p>

                                </div>
                            </div>
                            {(formState.delivery === 'spb' || formState.delivery === 'other') && (
                                <div className="order-form__row">
                                    <TextField
                                        required
                                        name="address"
                                        error={Boolean(formErrors.address)}
                                        value={formState.address}
                                        className="order-form__address"
                                        label="Адрес"
                                        variant="filled"
                                        onChange={formFieldHandler}
                                    />
                                </div>
                            )}
                            <div className="order-form__row">
                                <TextField
                                    required
                                    name="name"
                                    error={Boolean(formErrors.name)}
                                    value={formState.name}
                                    className="order-form__fio"
                                    label="Имя"
                                    variant="filled"
                                    onChange={formFieldHandler}
                                />
                            </div>
                            <div className="order-form__row">
                                <TextField
                                    required
                                    name="phone"
                                    error={Boolean(formErrors.phone)}
                                    value={formState.phone}
                                    type="phone"
                                    label="Телефон"
                                    className="order-form__phone"
                                    variant="filled"
                                    onChange={formFieldHandler}
                                />
                            </div>
                            <div className="order-form__row">
                                <TextField
                                    required
                                    name="email"
                                    error={Boolean(formErrors.email)}
                                    value={formState.email}
                                    type="email"
                                    label="Электронная почта"
                                    className="order-form__email"
                                    variant="filled"
                                    onChange={formFieldHandler}
                                />
                            </div>
                            <div className="order-form__row">
                                <TextField
                                    multiline
                                    fullWidth
                                    error={Boolean(formErrors.comment)}
                                    name="comment"
                                    rows={5}
                                    label="Комментарий"
                                    className="order-form__comments"
                                    variant="filled"
                                    onChange={formFieldHandler}
                                />
                            </div>
                        </div>

                        <div className="page-basket__total">Итого: {
                            basketCost >= deliveryThreshold || formState.delivery !== 'spb'
                                ? basketCost
                                : basketCost + deliveryCost
                        }р
                        </div>

                        <div className="order-form__submit">
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={
                                    !Array.isArray(basket)
                                    || basket.length === 0
                                    || !formState.isFormValid
                                    || formSubmitted
                                }
                                onClick={submit}
                            >
                                Оплатить
                            </Button>
                            {formErrors.other && (
                                <div className="order-form__submit-error">{formErrors.other}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="page-basket">
            <div className="page-basket__empty">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    className="page-basket__empty-img"
                >
                    <path
                        fill="currentColor"
                        d="M544 256H224a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h320a32 32 0 0 0 32-32V288a32 32 0 0 0-32-32zm0 224H224V288h320zm-172.63-32.81A96.12 96.12 0 0 0 480 352v-24a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v24a64.07 64.07 0 0 1-70.38 63.69c-33.25-3.23-57.62-33.12-57.62-66.53V328a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v20.66c0 48.79 35 92.34 83.37 98.53zM32 192h384v32h32v-32a32 32 0 0 0-32-32h-96V96a96 96 0 0 0-192 0v64H32a32 32 0 0 0-32 32v256a32 32 0 0 0 32 32h128v-32H32zm128-96a64 64 0 0 1 128 0v64H160z"
                    />
                </svg>
                <div className="page-basket__empty-msg">В корзине сейчас пусто.</div>
            </div>
        </div>
    );
};
