import { PRODUCT_TYPE } from "../models/product-type.js";

export const getProductText = (instance, type) => {
    if (!instance) {
        return '';
    }

    if (type === PRODUCT_TYPE.COFFEE_IN_PACKAGE) {
        return instance.isSet === true
            ? `Набор "${instance.name}"`
            : `Пачка "${instance.name}"`;
    }

    if (type === PRODUCT_TYPE.COLD_BREW) {
        return `${instance.name} "Cold Brew"`;
    }

    return instance.name;
};
