import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { PRODUCT_TYPE } from '../../../models/product-type';
import { PageHeader } from '../page-header/page-header';
import { PackageProps } from '../package-props/package-props';
import { getProduct } from "../../data-provider/product";
import { getProductCostText } from "../../../utils/get-product-cost-text";

import './page-packages-item.css';

const getAmountInBasket = (basket, product) => {
    for (let i = 0; i < basket.length; i += 1) {
        if (basket[i].type === product.type && basket[i].id === product.id) {
            return basket[i].quantity;
        }
    }

    return 0;
};

const getCost = (product) => getProductCostText(product, product.type);

export const PagePackagesItem = (props) => {
    const { addItem, basket, type } = props;
    const { id } = useParams();
    const product = getProduct(type, id);
    const amountInBasket = getAmountInBasket(basket, product);

    if (!product) {
        return <Redirect to="/404" />;
    }

    return (
        <>
            <div className="page-packages-item">
                <PageHeader text={product.name} />
                { product.image
                    ? (
                        <div className="page-packages-item__image">
                            <span
                                className="page-packages-item__image-placeholder"
                                style={{ paddingBottom: `${product.image.ratio * 100}%` }}
                            />
                            <img className="page-packages-item__image-img" src={product.image['800x']} alt="" />
                        </div>
                    )
                    : null }
                <div className="page-packages-item__body">
                    {product.type === PRODUCT_TYPE.COFFEE_IN_PACKAGE && (
                        <div className="page-packages-item__props">
                            <PackageProps
                                theme="default"
                                rows={[
                                    { name: 'Тип обжарки:', value: product.roasting },
                                    { name: 'Обработка:', value: product.processing },
                                    { name: 'Вес:', value: `${product.size}г` },
                                    { name: 'Во вкусе:', value: product.flavor },
                                    { name: 'Подходит для:', value: product.suitableFor },
                                ]}
                            />
                        </div>
                    )}
                    <div
                        className="page-packages-item__description wysiwyg-content"
                        dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                </div>
                <div className="page-packages-item__footer">
                    <div className="page-packages-item__cost">
                        <span className="page-packages-item__cost-label">Стоимость:</span>
                        {product.discount > 0
                            ? (
                                <>
                                    <div className="page-packages-item__cost-discount-price">
                                        {getCost(product)}
                                    </div>
                                    <div className="page-packages-item__cost-dismissed-value">
                                        {product.price}р
                                    </div>
                                </>
                            )
                            : (
                                <span className="page-packages-item__cost-val">
                                    {getCost(product)}
                                </span>
                            )}
                    </div>
                    <div className="page-packages-item__controls">
                        {product.isSold
                            ? <Button color="secondary" variant="outlined" disabled>Ожидается</Button>
                            : (
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => addItem(product.type, product.id)}
                                >
                                    Купить
                                </Button>
                            )
                        }
                        {!product.isSold && amountInBasket > 0
                            ? (
                                <span className="page-packages-item__controls-in-basket">
                                    В корзине: {amountInBasket}шт
                                </span>
                            )
                            : null
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
