import React from 'react';
import './package-props.css';

export const PackageProps = (props) => {
    const { rows } = props;

    return (
        <ul className={`package-props ${props.theme || ''}`}>
            {rows.map(row => (row.value
                ? (
                    <li className="package-props__row" key={escape(row.name)}>
                        <span className="package-props__name">{row.name}</span>
                        <span className="package-props__value">{row.value}</span>
                    </li>
                )
                : null
            ))}
        </ul>
    );
};
