import React from 'react';
import { Link } from 'react-router-dom';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { Add, Remove } from '@material-ui/icons';
import { PRODUCT_TYPE } from '../../../models/product-type';
import { getProduct } from "../../data-provider/product";
import { getProductCost } from "../../../utils/get-product-cost";
import { getProductText } from "../../../utils/get-product-text";

import './basket-list.css';

const getLineData = (orderPosition) => {
    const instance = getProduct(orderPosition.type, orderPosition.id);
    let url = null;

    if (orderPosition.type === PRODUCT_TYPE.COFFEE_IN_PACKAGE) {
        url = `/packages/${instance.id}`;
    }

    return {
        text: getProductText(instance, orderPosition.type),
        image: instance.image,
        price: getProductCost(instance, orderPosition.type),
        url
    };
};

export const BasketList = (props) => {
    const { addItem, removeItem, basket } = props;

    return (
        <div className="basket-list">
            {basket.map((orderPosition) => {
                const { text, price, image, url } = getLineData(orderPosition);
                const positionCost = price * orderPosition.quantity;

                return (
                    <div
                        className="basket-list__list-item"
                        key={`${orderPosition.type}-${orderPosition.id}`}
                    >
                        {image && url && (
                            <Link className="basket-list__item-image" to={url} title={text}>
                                <img className="basket-list__item-image-img" src={image['120x']} alt="" />
                            </Link>
                        )}
                        {(image && !url) && (
                            <span className="basket-list__item-image">
                                <img className="basket-list__item-image-img" src={image['120x']} alt="" />
                            </span>
                        )}
                        <div className="basket-list__item-body">
                            <div className="basket-list__list-item-price">{positionCost}р</div>
                            <div className="basket-list__list-item-title">{text}</div>
                            <ButtonGroup size="small">
                                <Button
                                    onClick={() => addItem(orderPosition.type, orderPosition.id)}
                                    className="basket-list__list-item-button basket-list__list-item-action"
                                >
                                    <Add />
                                </Button>
                                <Button
                                    disabled
                                    className="basket-list__list-item-button basket-list__list-item-num"
                                >
                                    {orderPosition.quantity}
                                </Button>
                                <Button
                                    onClick={() => removeItem(orderPosition.type, orderPosition.id)}
                                    className="basket-list__list-item-button basket-list__list-item-action"
                                >
                                    <Remove />
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
