import React from 'react';
import './page-error-404.css';

export const PageError404 = () => (
    <div className="page-error-404">
        <div className="page-error-404__title">404</div>
        <p>К сожалению, в нашем магазине нет такой страницы.<br />
            <span
                role="none"
                tabIndex="-1"
                onClick={() => window.history.back()}
                className="pseudo-link"
            >Вернуться назад
            </span>
        </p>
    </div>
);
