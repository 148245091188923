import { PRODUCT_TYPE } from '../../models/product-type';

const PRODUCTS = window.PRODUCTS || [];

export const getProducts = () => PRODUCTS.map((listItem) => {
    const rawProduct = listItem.product;
    const result = {
        type: listItem.type,
        id: String(rawProduct.id),
        name: rawProduct.name,
        discount: rawProduct.discount || 0,
        price: rawProduct.price,
        isSold: rawProduct.isSold === true,
        score: listItem.score,
    };

    if (rawProduct.image) {
        result.image = {
            ratio: rawProduct.image.h / rawProduct.image.w,
            '240x': rawProduct.image['240x'],
        }
    }

    if (listItem.type === PRODUCT_TYPE.COFFEE_IN_PACKAGE) {
        Object.assign(result, {
            suitableFor: rawProduct.suitableFor,
            roasting: rawProduct.roasting,
            flavor: rawProduct.flavor,
        });
    } else if (listItem.type === PRODUCT_TYPE.SHOPPER) {
        Object.assign(result, {
            description: rawProduct.description,
            width: String(rawProduct.width),
            height: String(rawProduct.height),
        });
    } else if (listItem.type === PRODUCT_TYPE.CERTIFICATE) {
        Object.assign(result, {
            description: rawProduct.description,
        });
    } else if (listItem.type === PRODUCT_TYPE.COLD_BREW) {
        Object.assign(result, {
            description: rawProduct.except,
            size: rawProduct.size,
        });
    } else if ([
        PRODUCT_TYPE.DRIP_COFFEE_PACKAGE,
        PRODUCT_TYPE.NEW_YEAR_PACKAGE,
    ].includes(listItem.type)) {
        Object.assign(result, {
            description: rawProduct.except,
        });
    }

    return result;
});

export const getProduct = (type, id) => {
    for (let i = 0; i < PRODUCTS.length; i++) {
        const listItem = PRODUCTS[i];
        const rawProduct = listItem.product;

        if (listItem.type === type && String(rawProduct.id) === String(id)) {
            const result = {
                type: listItem.type,
                id: String(rawProduct.id),
                name: rawProduct.name,
                price: rawProduct.price,
                description: rawProduct.description,
                isSold: rawProduct.isSold === true,
            };

            if (rawProduct.image) {
                result.image = {
                    ratio: rawProduct.image.h / rawProduct.image.w,
                    '120x': rawProduct.image['120x'],
                    '800x': rawProduct.image['800x'],
                }
            }

            if (listItem.type === PRODUCT_TYPE.COFFEE_IN_PACKAGE) {
                Object.assign(result, {
                    discount: rawProduct.discount || 0,
                    roasting: rawProduct.roasting,
                    processing: rawProduct.processing,
                    size: rawProduct.size,
                    flavor: rawProduct.flavor,
                    suitableFor: rawProduct.suitableFor,
                    isSet: rawProduct.isSet === true,
                });
            } else if (listItem.type === PRODUCT_TYPE.SHOPPER) {
                Object.assign(result, {
                    width: String(rawProduct.width),
                    height: String(rawProduct.height),
                });
            }

            return result;
        }
    }
};
