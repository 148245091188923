import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { PRODUCT_TYPE } from '../../../models/product-type';
import { getProducts } from '../../data-provider/product';
import { getProductCostText } from "../../../utils/get-product-cost-text";
import { PackageProps } from '../package-props/package-props';

import './page-packages-list.css';

const getKey = (type, id) => `${type}-${id}`;

const calculateBasket = (basket) => {
    const data = {};

    basket.forEach((position) => {
        const key = getKey(position.type, position.id);

        if (data[key]) {
            data[key] += position.quantity;
        } else {
            data[key] = position.quantity;
        }
    });

    return data;
};

const getPrice = (product) => getProductCostText(product, product.type);

export const PagePackagesList = (props) => {
    const { addItem, basket } = props;
    const basketData = calculateBasket(basket);
    const productsList = getProducts()
        .sort((left, right) => {
            const lScoreParsed = parseInt(left.score, 10);
            const rScoreParsed = parseInt(right.score, 10);
            const lScore = lScoreParsed > 0 || lScoreParsed < 0 ? lScoreParsed : 0;
            const rScore = rScoreParsed > 0 || rScoreParsed < 0 ? rScoreParsed : 0;

            if (lScore === 0 && rScore === 0 && lScoreParsed !== 0 && rScoreParsed !== 0) {
                return parseInt(left.id, 10) - parseInt(right.id, 10);
            }

            return rScore - lScore;
        });

    return (
        <>
            <div className="page-packages-list__how-to-choose">
                <Link to="/packages/how-to-choose" className="page-packages-list__how-to-choose-link">
                    <HelpOutlineIcon fontSize="small" className="page-packages-list__how-to-choose-icon" />
                    <span className="page-packages-list__how-to-choose-text">Как выбрать кофе домой?</span>
                </Link>
            </div>
            <div className="page-packages-list__list">
                {productsList.map((product) => {
                    const image = product.image
                        ? (
                            <img
                                className="page-packages-list__package-image-img"
                                src={product.image['240x']}
                                alt=""
                            />
                        )
                        : null;
                    let productUrl;

                    if (product.type === PRODUCT_TYPE.COFFEE_IN_PACKAGE) {
                        productUrl = `/packages/${product.id}`;
                    } else if (product.type === PRODUCT_TYPE.DRIP_COFFEE_PACKAGE) {
                        productUrl = `/drip-coffee-packages/${product.id}`;
                    } else if (product.type === PRODUCT_TYPE.NEW_YEAR_PACKAGE) {
                        productUrl = `/new-year-package/${product.id}`;
                    }

                    return (
                        <div className="page-packages-list__list-item" key={`${product.type}-${product.id}`}>
                            <div className="page-packages-list__package">
                                {productUrl ? (
                                    <Link
                                        to={productUrl}
                                        className="page-packages-list__package-image"
                                        title={product.name}
                                    >
                                        {product.discount > 0 && (
                                            <div className="page-packages-list__package-discount-label">
                                                {product.discount}%
                                            </div>
                                        )}
                                        {image}
                                    </Link>
                                ) : (
                                    <div className="page-packages-list__package-image">
                                        {product.discount > 0 && (
                                            <div className="page-packages-list__package-discount-label">
                                                {product.discount}%
                                            </div>
                                        )}
                                        {image}
                                    </div>
                                )}
                                {product.discount > 0
                                    ? (
                                        <>
                                            <div
                                                className={[
                                                    'page-packages-list__package-price',
                                                    'page-packages-list__package-price_dismissed',
                                                ].join(' ')}
                                            >
                                                {product.price}р
                                            </div>
                                            <div className="page-packages-list__package-discount-price">
                                                {getPrice(product)}
                                            </div>
                                        </>
                                    )
                                    : (
                                        <div className="page-packages-list__package-price">{getPrice(product)}</div>
                                    )}
                                <h3 className="page-packages-list__package-name">
                                    {productUrl ? (
                                        <Link to={productUrl} className="page-packages-list__package-name-link">
                                            {product.name}
                                        </Link>
                                    ) : (
                                        <span className="page-packages-list__package-name-link">
                                            {product.name}
                                        </span>
                                    )}
                                </h3>
                                {product.description && (
                                    <div className="page-packages-list__package-description">
                                        {product.description}
                                    </div>
                                )}
                                {product.type === PRODUCT_TYPE.COFFEE_IN_PACKAGE && (
                                    <div className="page-packages-list__package-props">
                                        <PackageProps
                                            theme="compact"
                                            rows={[
                                                {
                                                    name: 'Подходит для:',
                                                    value: product.suitableFor,
                                                },
                                                {
                                                    name: 'Тип обжарки:',
                                                    value: product.roasting,
                                                },
                                                {
                                                    name: 'Во вкусе:',
                                                    value: product.flavor,
                                                },
                                            ]}
                                        />
                                    </div>
                                )}
                                {product.type === PRODUCT_TYPE.SHOPPER && (
                                    <div className="page-packages-list__package-props">
                                        <PackageProps
                                            theme="compact"
                                            rows={[
                                                {
                                                    name: 'Размер:',
                                                    value: `${product.width}x${product.height}см`,
                                                },
                                            ]}
                                        />
                                    </div>
                                )}
                                {product.type === PRODUCT_TYPE.COLD_BREW && (
                                    <div className="page-packages-list__package-props">
                                        <PackageProps
                                            theme="compact"
                                            rows={[
                                                {
                                                    name: 'Объём:',
                                                    value: `${product.size}л`,
                                                },
                                            ]}
                                        />
                                    </div>
                                )}
                                <div className="page-packages-list__package-controls">
                                    {product.isSold
                                        ? <Button color="secondary" variant="outlined" disabled>Ожидается</Button>
                                        : (
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={() => addItem(product.type, product.id)}
                                            >
                                                Купить
                                            </Button>
                                        )
                                    }
                                    {!product.isSold && basketData[getKey(product.type, product.id)] > 0
                                        ? (
                                            <span className="page-packages-list__package-controls-in-basket">
                                                В корзине: {basketData[getKey(product.type, product.id)]}шт
                                            </span>
                                        )
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
